body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: rgb(246, 248, 250);
    border-radius: 6px;
    margin-bottom: 0;
    word-break: normal;
}

:not(pre) > code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: #afb8c133;
    border-radius: 6px;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
        Liberation Mono, monospace;
}

code[class*='language-'],
pre[class*='language-'] {
    color: #393a34;
    font-family: 'Consolas', 'Bitstream Vera Sans Mono', 'Courier New', Courier,
        monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    font-size: 0.9em;
    line-height: 1.2em;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre > code[class*='language-'] {
    font-size: 1em;
}

pre[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection {
    background: #c1def1;
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
    background: #c1def1;
}

/* Code blocks */
pre[class*='language-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border: 1px solid #dddddd;
    background-color: white;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
    padding: 0.2em;
    padding-top: 1px;
    padding-bottom: 1px;
    background: #f8f8f8;
    border: 1px solid #dddddd;
}

pre[class*='language-'],
code[class*='language-'] {
    color: #d4d4d4;
    font-size: 13px;
    text-shadow: none;
    font-family: Menlo, Monaco, Consolas, 'Andale Mono', 'Ubuntu Mono',
        'Courier New', monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre[class*='language-']::selection,
code[class*='language-']::selection,
pre[class*='language-'] *::selection,
code[class*='language-'] *::selection {
    text-shadow: none;
    background: #264f78;
}

@media print {
    pre[class*='language-'],
    code[class*='language-'] {
        text-shadow: none;
    }
}

pre[class*='language-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    background: #1e1e1e;
}

:not(pre) > code[class*='language-'] {
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    color: #db4c69;
    background: #1e1e1e;
}
/*********************************************************
* Tokens
*/
.namespace {
    opacity: 0.7;
}

.token.doctype .token.doctype-tag {
    color: #569cd6;
}

.token.doctype .token.name {
    color: #9cdcfe;
}

.token.comment,
.token.prolog {
    color: #6a9955;
}

.token.punctuation,
.language-html .language-css .token.punctuation,
.language-html .language-javascript .token.punctuation {
    color: #d4d4d4;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.inserted,
.token.unit {
    color: #b5cea8;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.deleted {
    color: #ce9178;
}

.language-css .token.string.url {
    text-decoration: underline;
}

.token.operator,
.token.entity {
    color: #d4d4d4;
}

.token.operator.arrow {
    color: #569cd6;
}

.token.atrule {
    color: #ce9178;
}

.token.atrule .token.rule {
    color: #c586c0;
}

.token.atrule .token.url {
    color: #9cdcfe;
}

.token.atrule .token.url .token.function {
    color: #dcdcaa;
}

.token.atrule .token.url .token.punctuation {
    color: #d4d4d4;
}

.token.keyword {
    color: #569cd6;
}

.token.keyword.module,
.token.keyword.control-flow {
    color: #c586c0;
}

.token.function,
.token.function .token.maybe-class-name {
    color: #dcdcaa;
}

.token.regex {
    color: #d16969;
}

.token.important {
    color: #569cd6;
}

.token.italic {
    font-style: italic;
}

.token.constant {
    color: #9cdcfe;
}

.token.class-name,
.token.maybe-class-name {
    color: #4ec9b0;
}

.token.console {
    color: #9cdcfe;
}

.token.parameter {
    color: #9cdcfe;
}

.token.interpolation {
    color: #9cdcfe;
}

.token.punctuation.interpolation-punctuation {
    color: #569cd6;
}

.token.boolean {
    color: #569cd6;
}

.token.property,
.token.variable,
.token.imports .token.maybe-class-name,
.token.exports .token.maybe-class-name {
    color: #9cdcfe;
}

.token.selector {
    color: #d7ba7d;
}

.token.escape {
    color: #d7ba7d;
}

.token.tag {
    color: #569cd6;
}

.token.tag .token.punctuation {
    color: #808080;
}

.token.cdata {
    color: #808080;
}

.token.attr-name {
    color: #9cdcfe;
}

.token.attr-value,
.token.attr-value .token.punctuation {
    color: #ce9178;
}

.token.attr-value .token.punctuation.attr-equals {
    color: #d4d4d4;
}

.token.entity {
    color: #569cd6;
}

.token.namespace {
    color: #4ec9b0;
}
/*********************************************************
* Language Specific
*/

pre[class*='language-javascript'],
code[class*='language-javascript'],
pre[class*='language-jsx'],
code[class*='language-jsx'],
pre[class*='language-typescript'],
code[class*='language-typescript'],
pre[class*='language-tsx'],
code[class*='language-tsx'] {
    color: #9cdcfe;
}

pre[class*='language-css'],
code[class*='language-css'] {
    color: #ce9178;
}

pre[class*='language-html'],
code[class*='language-html'] {
    color: #d4d4d4;
}

.language-regex .token.anchor {
    color: #dcdcaa;
}

.language-html .token.punctuation {
    color: #808080;
}
/*********************************************************
* Line highlighting
*/
pre[class*='language-'] > code[class*='language-'] {
    position: relative;
    z-index: 1;
}

.line-highlight.line-highlight {
    background: #f7ebc6;
    box-shadow: inset 5px 0 0 #f7d87c;
    z-index: 0;
}
