html,
body,
#root {
    height: 100%;
    background-color: #fafbff;
}

.task-list-item {
    list-style-type: none;
}
